import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import AboutPage from '../templates/about.js';
import { colors } from '../styles';
export const _frontmatter = {
  "title": "About Solar Energy Partners"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Hero = makeShortcode("Hero");
const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Counter = makeShortcode("Counter");
const SplitSection = makeShortcode("SplitSection");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = AboutPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Hero mdxType="Hero" />
    <Section mdxType="Section">
      <Container mdxType="Container"><Row alignX='center' mdxType="Row">
          <Column sm={40} style={{
            textAlign: 'center',
            color: '#000000'
          }} mdxType="Column">
            <p>{`We Have Helped Over`}</p>
            <Counter startDigit={1000} digit={5500} divider={false} style={{
              marginBottom: 10,
              color: colors.main
            }} mdxType="Counter" />
            <p>{`Customers Switch To Solar`}</p>
          </Column>
          <Column sm={40} style={{
            textAlign: 'center',
            color: '#000000'
          }} mdxType="Column">
            <p>{`Our Customers Have Saved Over`}</p>
            <Counter digit={15} textBefore="$" textAfter="M" divider={false} style={{
              marginBottom: 10,
              color: colors.main
            }} mdxType="Counter" />
            <p>{`By Switching To Solar`}</p>
          </Column>
        </Row></Container>
    </Section>
    <SplitSection img='about2' title='About Us' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`With 20 years of solar experience, Solar Energy Partners was founded by Alex Williams, Clint Williams, and Dave Madrid in the spring of 2017.`}</p>
      <p style={{
        fontSize: '1.15rem',
        fontWeight: 700
      }}>
    "More options for our customers means more deals for our reps and better
    deals for the customer."
      </p>
      <p>{`With that mantra in mind, we've committed to creating partnerships with many of the largest, and highest rated companies in the Solar Industry. Our list of partners includes Bright Planet Solar, SunRun, Infinity Energy, SunNova, EXO energy, Tesla, and many more.`}</p>
    </SplitSection>
    <SplitSection img='about1' title='Our Mission' position='left' mdxType="SplitSection">
      <p>{`Bringing clean energy, abundant savings, and peace of mind to every homeowner in the United States.`}</p>
      <p>{`The solar revolution that is overtaking the world continues to progress every year, and SEP is leading the charge in spreading renewable resources across the world.`}</p>
      <p>{`Join us in saving our planet through sustainable energy methods while lowering your monthly energy costs.`}</p>
      <Button to="/explore-solar/" mdxType="Button">Get Started</Button>
    </SplitSection>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      